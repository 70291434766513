import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='footer'>
      Copyright @ <span>DR.RADHIKA MULTI SPECIALITY HOSPITALS </span> | 2024
    </footer>
  );
};

export default Footer;
